// if (window.location.pathname === "/epoch") {
//   document.body.style.overflow = "hidden";

//   document.addEventListener("DOMContentLoaded", () => {
//     window.scrollTo({ top: 0, behavior: "smooth" });

//     console.log(window.apidata);

//     mapboxgl.accessToken =
//       "pk.eyJ1Ijoic2hhbWFucHl0aG9uIiwiYSI6ImNrbzFjaDh2NzA3Y2gyd2x5Z3l2dnNtNXIifQ.odcYGYXS4EH4yKwqNHPIaw";

//     setTimeout(() => {
//       const POINTS = JSON.parse(window.apidata.content.event_points);
//       const ROUTE = JSON.parse(window.apidata.content.route);
//       const START = POINTS.features[0].geometry.coordinates;
//       const CENTER =
//         POINTS.features[Math.floor(POINTS.features.length / 2)].geometry
//           .coordinates;

//       // Инициализирую карту
//       const map = new mapboxgl.Map({
//         container: "map",
//         style: "mapbox://styles/mapbox/light-v10",
//         center: CENTER,
//         zoom: 8,
//         pitch: 60, // Наклон карты
//         bearing: 60, // Поворот карты
//       });

//       map.addControl(new mapboxgl.NavigationControl());

//       // Плавно подлетаю к месту событий
//       setTimeout(() => FlyTo(map, CENTER, 13), 2000);

//       map.on("load", function () {
//         document.body.style.overflow = "auto";

//         // Добавление неба
//         map.addLayer({
//           id: "sky",
//           type: "sky",
//           paint: {
//             "sky-type": "atmosphere",

//             "sky-atmosphere-sun": [90, 80],
//             "sky-atmosphere-sun-intensity": 15,
//           },
//         });

//         // Добавления объема
//         // map.addSource("mapbox-dem", {
//         //   type: "raster-dem",
//         //   url: "mapbox://mapbox.mapbox-terrain-dem-v1",
//         //   tileSize: 512,
//         //   maxzoom: 14,
//         // });
//         // // add the DEM source as a terrain layer with exaggerated height
//         // map.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });

//         let popup = new mapboxgl.Popup({
//           closeButton: false,
//         });

//         // Добавляю картинку события
//         map.loadImage("assets/icons/event.png", function (error, image) {
//           if (error) {
//             console.error("Изображение event.png не было найдено");
//             throw error;
//           } else {
//             map.addImage("event", image);
//           }
//         });
//         // Добавляю картинку битвы
//         map.loadImage("assets/icons/battle.png", function (error, image) {
//           if (error) {
//             console.error("Изображение battle.png не было найдено");
//             throw error;
//           } else {
//             map.addImage("battle", image);
//           }
//         });

//         // Отображаю всю линию движения в данной эпохе
//         map.addSource("trace", { type: "geojson", data: ROUTE });
//         DrawLine(
//           map,
//           "trace",
//           "trace",
//           ROUTE.features[0].properties.color,
//           ROUTE.features[0].properties.opacity,
//           ROUTE.features[0].properties.width
//         );

//         // Отображаю все события в данной эпохе
//         map.addSource("points", { type: "geojson", data: POINTS });

//         // Переношу камеру на событие к которому кликнул пользователь
//         map.on("click", "points", function (e) {
//           map.flyTo({
//             center: e.features[0].geometry.coordinates,
//           });
//         });

//         // Меняю курсор на pointer при наведении на точку
//         map.on("mousemove", "points", (e) => {
//           map.getCanvas().style.cursor = "pointer";
//           popup
//             .setLngLat(e.lngLat)
//             .setHTML(e.features[0].properties.name)
//             .addTo(map);
//         });

//         // Меняю курсор на дефлотное состояние
//         map.on("mouseleave", "points", () => {
//           map.getCanvas().style.cursor = "";
//           popup.remove();
//         });

//         DrawPoints(map, "points", "points", "battle");

//         let i = 0;
//         let activeEvent = 0;
//         let viewedEpochs = new Array();
//         window.polygons = new Array();
//         window.hideEpochEvent = false;

//         window.animationStepper = 1;

//         // Контент событий попал в область просмотра
//         document.addEventListener("aos:in:map-road", async (e) => {
//           // Проверяю, просматривал ли пользователь уже это событие
//           if (!viewedEpochs.includes(Number(e.detail.id.split("_")[1]))) {
//             activeEvent = Number(e.detail.id.split("_")[1]);

//             // Отмечаю событие как просмотренное
//             viewedEpochs.push(activeEvent);

//             // Записываю и перерисовываю какие точки событий отображать
//             setPointsSourceData(map, POINTS, activeEvent);

//             // Записываю какие части маршрута отображать
//             ROUTE.features = getRange(
//               JSON.parse(window.apidata.content.route).features,
//               0,
//               activeEvent
//             );

//             // Проверяю, не дошли ли до конца маршрута
//             if (activeEvent <= ROUTE.features.length) {
//               let coordinates =
//                 ROUTE.features[activeEvent - 1].geometry.coordinates;
//               ROUTE.features[activeEvent - 1].geometry.coordinates = [
//                 coordinates[0],
//               ];

//               FlyTo(map, coordinates[i], 15, 0.3);

//               window.hideEpochEvent = false;
//               window.animationStepper = activeEvent;

//               if (getEventType(activeEvent) === "normal") {
//                 // Если есть отображаемые развернутые события -> убираю их
//                 if (window.polygons.length > 0) {
//                   DeleteBigEvent(
//                     map,
//                     POINTS.features[POINTS.features.length - 1].geometry
//                       .coordinates
//                   );
//                 }

//                 DrawLineStepByStep(
//                   map,
//                   coordinates,
//                   ROUTE,
//                   POINTS,
//                   activeEvent
//                 );
//               } else {
//                 DrawBigEvent(
//                   map,
//                   window.apidata.events[activeEvent - 1].content
//                 );
//               }
//             }
//           }
//           // Если скролит вверх
//           else {
//             let ev = document.getElementById(e.detail.id);
//             const posTop = ev.getBoundingClientRect().top;
//             window.hideEpochEvent = true;

//             if (posTop < window.innerHeight) {
//               if (Number(e.detail.id.split("_")[1]) > 1) {
//                 // Записываю и перерисовываю какие точки событий отображать
//                 setPointsSourceData(
//                   map,
//                   POINTS,
//                   Number(e.detail.id.split("_")[1]) - 1
//                 );

//                 // Записываю какие части маршрута отображать
//                 ROUTE.features = getRange(
//                   JSON.parse(window.apidata.content.route).features,
//                   0,
//                   Number(e.detail.id.split("_")[1]) - 2
//                 );

//                 let backPoint = JSON.parse(window.apidata.content.event_points)
//                   .features[Number(e.detail.id.split("_")[1]) - 2].geometry
//                   .coordinates;

//                 for (let i = 0; i < window.apidata.events.length; i++) {
//                   if (i === activeEvent - 1) {
//                     eventType = window.apidata.events[i].type;
//                     console.log(eventType);
//                   }
//                 }

//                 FlyTo(map, backPoint, 17, 0.6);

//                 if (
//                   getEventType(Number(e.detail.id.split("_")[1]) - 1) ===
//                   "normal"
//                 ) {
//                   if (window.polygons.length > 0) {
//                     DeleteBigEvent(
//                       map,
//                       POINTS.features[POINTS.features.length - 1].geometry
//                         .coordinates
//                     );
//                   }
//                   map.getSource("trace").setData(ROUTE);

//                   deleteCurrentElement(
//                     viewedEpochs,
//                     Number(e.detail.id.split("_")[1])
//                   );
//                 } else {
//                   DrawBigEvent(
//                     map,
//                     window.apidata.events[Number(e.detail.id.split("_")[1]) - 2]
//                       .content
//                   );
//                 }
//               } else {
//                 window.animationStepper = 1;
//                 activeEvent = 0;
//                 i = 0;

//                 map
//                   .getSource("trace")
//                   .setData(JSON.parse(window.apidata.content.route));

//                 map
//                   .getSource("points")
//                   .setData(JSON.parse(window.apidata.content.event_points));

//                 FlyTo(map, CENTER, 13, 0.6);

//                 viewedEpochs = [];
//               }
//             }
//           }
//         });
//       });
//     }, 3000);
//   });
// }

// // Нарисовать точки
// function DrawPoints(map, idValue, sourceValue) {
//   map.addLayer({
//     id: idValue,
//     type: "symbol",
//     source: sourceValue,
//     layout: {
//       "icon-image": "{icon}",
//       // "text-field": "{name}",
//       "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
//       "text-offset": [0, 0.6],
//       "icon-size": 0.9,
//       "text-anchor": "top",
//     },
//   });
// }

// // Пошаговая прорисовка маршрута
// function DrawLineStepByStep(map, coordinates, route, points, activeEvent) {
//   let i = 0;

//   let timer = window.setInterval(() => {
//     if (
//       !window.hideEpochEvent &&
//       i < coordinates.length &&
//       window.animationStepper === activeEvent
//     ) {
//       route.features[activeEvent - 1].geometry.coordinates.push(coordinates[i]);
//       map.getSource("trace").setData(route);

//       if (i === coordinates.length - 1) {
//         FlyTo(map, coordinates[i], 17, 0.6);
//         // Записываю и перерисовываю какие точки событий отображать
//         setPointsSourceData(map, points, activeEvent + 1);
//       } else {
//         map.jumpTo({ center: coordinates[i], zoom: 15 });
//         // FlyTo(map, coordinates[i], 15, 0.3);
//       }

//       i++;
//     } else {
//       i = 0;
//       window.clearInterval(timer);
//     }
//   }, 100);
// }

// function DeleteBigEvent(map, cameraPosition) {
//   for (let i = 0; i < window.polygons.length; i++) {
//     map.removeLayer(window.polygons[i]);
//     map.removeSource(window.polygons[i]);
//   }
//   // Очищаю массив полигонов
//   window.polygons = new Array();
//   // Возвращаю основную синию маршрута и точки
//   map.setLayoutProperty("trace", "visibility", "visible");
//   map.setLayoutProperty("points", "visibility", "visible");

//   FlyTo(map, cameraPosition, 15, 0.6);
// }

// function DrawBigEvent(map, content) {
//   const center = content.center_of_the_event.replace(/\s+/g, "").split(",");
//   const layers = JSON.parse(content.layers);

//   // Скрываю основную синию маршрута и точки
//   map.setLayoutProperty("trace", "visibility", "none");
//   map.setLayoutProperty("points", "visibility", "none");

//   for (let i = 0; i < layers.features.length; i++) {
//     // Сохраняю какой полигон нанесен на карту
//     window.polygons.push("big_event_" + i);

//     map.addSource("big_event_" + i, {
//       type: "geojson",
//       data: {
//         type: "Feature",
//         geometry: {
//           type: "Polygon",
//           coordinates: layers.features[i].geometry.coordinates,
//         },
//       },
//     });

//     map.addLayer({
//       id: "big_event_" + i,
//       type: "fill",
//       source: "big_event_" + i, // reference the data source
//       layout: {},
//       paint: {
//         "fill-color": layers.features[i].properties.fill, // blue color fill
//         "fill-opacity": layers.features[i].properties["fill-opacity"],
//         "fill-outline-color": layers.features[i].properties.stroke,
//       },
//     });
//   }

//   FlyTo(
//     map,
//     center,
//     Number(content.map_scale),
//     0.6,
//     Number(content.map_corner)
//   );
// }

// // Нарисовать линию
// function DrawLine(
//   map,
//   idValue,
//   sourceValue,
//   lineColor,
//   lineOpacity,
//   lineWidth
// ) {
//   map.addLayer({
//     id: idValue,
//     type: "line",
//     source: sourceValue,
//     layout: {
//       "line-join": "round",
//       "line-cap": "round",
//     },
//     paint: {
//       "line-color": lineColor,
//       "line-opacity": lineOpacity,
//       "line-width": lineWidth,
//     },
//   });
// }

// // Плавно перенести камеру к нужной точке
// function FlyTo(
//   map,
//   centerValue,
//   zoomValue = 15,
//   speedValue = 0.9,
//   bearingVlue = 0
// ) {
//   map.flyTo({
//     center: centerValue,
//     zoom: zoomValue,
//     bearing: bearingVlue,

//     speed: speedValue, // make the flying slow
//     curve: 1, // change the speed at which it zooms out

//     easing: function (t) {
//       return t;
//     },

//     essential: true,
//   });
// }

// // Получить диапазон
// function getRange(array, index, range) {
//   var least = index - Math.floor(range / 2);
//   least = least < 0 ? 0 : least;
//   return array.slice(least, least + range);
// }

// // удалить определенный элмент из массива
// function deleteCurrentElement(array, elem) {
//   const index = array.indexOf(elem);
//   if (index > -1) {
//     array.splice(index, 1);
//   }
// }

// function setPointsSourceData(map, points, event) {
//   points.features = getRange(
//     JSON.parse(window.apidata.content.event_points).features,
//     0,
//     event
//   );

//   map.getSource("points").setData(points);
// }

// // Получить позицию элемента относительно положения и видимости на экране
// function getElementScrollPosition(id) {
//   let ev = document.getElementById(id);
//   return ev.getBoundingClientRect().top;
// }

// function getEventType(activeEvent) {
//   for (let i = 0; i < window.apidata.events.length; i++) {
//     if (i === activeEvent - 1) {
//       return window.apidata.events[i].type;
//     }
//   }
// }

let hiddenChapters = new Array();
let showChapters = new Array();

mapboxgl.accessToken =
  "pk.eyJ1Ijoic2hhbWFucHl0aG9uIiwiYSI6ImNrbzFjaDh2NzA3Y2gyd2x5Z3l2dnNtNXIifQ.odcYGYXS4EH4yKwqNHPIaw";

const funcHiddenChapters = {};
const funcShowChapters = {};
const funcHiddenPoints = {};
const funcShowPoints = {};

// const LINE_COLOR = "#9F8981";

let data = {};
let metaData = {};

const ORDER = new URLSearchParams(window.location.search).get('order');

let MAP = new Object();
let ANOTHER_ROUTES = [];
let ANOTHER_POINTS = [];
let POINTS = {};
let ROUTE = {};

var bStatus = false;
let counter = 0;

const initShowRoutesCameraProperties = {
  center: [20.31744358255162, 54.30458820714759],
  zoom: 3.8302836769115847,
  pitch: 42.99999999999998,
  bearing: -0,
};

const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i;

const route_colors = {
  1: "#958079",
  2: "#AB843B",
  3: "#AFA93C",
  4: "#76AB3B",
  5: "#39A788",
  6: "#3893A3",
  7: "#3770A0",
  8: "#5838A3",
}

const isScrollDown = useIsScrollingDown();
const cachedChapter = useCachedValue(null);
const toggleRoutesScope = useToggleRoutesScope();
const toggleAnotherRoutes = toggleRoutesScope.click;
const cachedPopup = useCachedValue(null);
let mapListeners = useRigsterListenersScope();

let isStopListenig = true;

function scrollOnTopListener() {
  if (window.pageYOffset === 0) {
    isStopListenig = false;
    window.removeEventListener('scroll', scrollOnTopListener)
  }
}

function handleScrollWhenContentChanged() {
  if (window.pageYOffset !== 0) {
    isStopListenig = true;
    window.addEventListener('scroll', scrollOnTopListener);
  } else {
    isStopListenig = false;
  }
}

document.addEventListener('epochShowRequest', async (ev) => {
  handleScrollWhenContentChanged();
  if(!mapListeners) {
    mapListeners = useRigsterListenersScope();
  }
  data = ev.detail.data;
  const meta = setMetaData();
  // document.body.style.overflow = "hidden";
  if (meta.currentEpoch) {
    await renderMap();
  }
})

document.addEventListener('requestChangeEpoch', async () => {
  window.scrollTo(0,0);
  handleScrollWhenContentChanged();
  setMetaData();
  await updateMap();
})


document.addEventListener('requestRemoveEpoch', () => {
  MAP.remove();
  mapListeners = null;
});

document.addEventListener('navigating', () => {
  handleScrollWhenContentChanged();
});

async function renderMap(){
  await createMap();
  addLayersAndHandlers();
}

async function updateMap() {
  mapListeners && mapListeners.removeListeners(MAP);
  data.routes?.forEach((route) => {
    Object.keys(route).forEach((key) => {
      MAP?.removeLayer('part_' + key)
    })
  })
  data.epochs?.forEach((epoch) => {
    const evPoints = JSON.parse(epoch.content.event_points);
    Object.keys(evPoints).forEach((key) => {
      if (key.split('_')[1] !== '0') {
        MAP?.removeLayer(('point_' + key));
      }
    })
  })
  addLayersAndHandlers();
  const imageUrlDefault = `assets/icons/battle_${getOrder() || 1}.png`
  try {
    await MAP.loadImage(imageUrlDefault, function (error, image) {
      if (error) throw error;
      MAP.updateImage("event", image);
    });
    MAP.flyTo({
      center: POINTS[metaData.currentEpoch.order + "_0"].center,
      zoom: POINTS[metaData.currentEpoch.order + "_0"].zoom,
      pitch: POINTS[metaData.currentEpoch.order + "_0"].pitch,
      bearing: POINTS[metaData.currentEpoch.order + "_0"].bearing,
    });
  } catch (error) {
    console.log('error');
  }
}

async function createMap() {
  const map = new mapboxgl.Map({
    container: "map", // Контеннер с картой DIV          
    style: "mapbox://styles/shamanpython/cks7nhdct29in17p4mrb297jg", // Стиль карты9F8981
    center: POINTS[metaData.currentEpoch.order + "_0"].center, // Начальное положение
    zoom: POINTS[metaData.currentEpoch.order + "_0"].zoom, // Начальный масштаб
    pitch: POINTS[metaData.currentEpoch.order + "_0"].pitch, // Наклон карты
    bearing: POINTS[metaData.currentEpoch.order + "_0"].bearing, // Поворот карты
  });

  MAP = map;

  // Добавление переключения позиции камеры показа всех/одного маршрута
  toggleRoutesScope.addHandler(useCameraState(map));

  // Добавление элементов управления картой
  const nav = new mapboxgl.NavigationControl(); // масштабирование поворот

  map.addControl(new mapboxgl.FullscreenControl());
  map.addControl(nav, "top-right");

  // Добавление изображений точек
  const imageUrlDefault = `assets/icons/battle_${getOrder() || 1}.png`
  await map.loadImage(imageUrlDefault, function (error, image) {
    if (error) throw error;
    map.addImage("event", image);
  });
  await map.loadImage("assets/icons/battle.png", function (error, image) {
    if (error) throw error;
    map.addImage("battle", image);
  });
  for (let key in route_colors) {
    await map.loadImage(`assets/icons/battle_${key}.png`, function (error, image) {
      if (error) throw error;
      map.addImage(`battle_${key}`, image);
    })
  }

  await map.once("load");

  // Возвращаю возможность скрола
  // document.body.style.overflow = "auto";

  // Добавление неба
  map.addLayer({
    id: "sky",
    type: "sky",
    paint: {
      "sky-type": "atmosphere",
      "sky-atmosphere-sun": [0.0, 85.0],
      "sky-atmosphere-sun-intensity": 15,
    },
  });

  // Добавления объема
  map.addSource("mapbox-dem", {
    type: "raster-dem",
    url: "mapbox://mapbox.mapbox-terrain-dem-v1",
    tileSize: 512,
    maxzoom: 14,
  });
  // add the DEM source as a terrain layer with exaggerated height
  map.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
}

function addLayersAndHandlers() {
  ANOTHER_ROUTES?.forEach((currentRoute, index) => {
    for (let k in currentRoute) {
      addRoutePart(MAP, currentRoute[k], "part_" + k, true);
      MAP.setPaintProperty("part_" + k, "line-gradient", [
        "step",
        ["line-progress"],
        route_colors[k.charAt(0)],
        0,
        "rgba(255, 0, 0, 0)",
      ]);
      MAP.setPaintProperty("part_" + k, "line-width", 4);
    }
  })

  ANOTHER_POINTS?.forEach(async (pointGroup) => {
    setFuncChapters(pointGroup, MAP);
    for (let k in pointGroup) {
      if (k.split('_').slice(-1)[0] !== '0') {
        await addPoint(MAP, pointGroup[k], "point_" + k);
        MAP.setLayoutProperty(
          "point_" + k,
          "visibility",
          "none"
        );
        MAP.setLayoutProperty(
          "point_" + k,
          "icon-image",
          `battle_${k.charAt(0)}`
        );
      }
    }
  })

  // Добавление частей маршрута
 
  for (let i in ROUTE) {
    addRoutePart(MAP, ROUTE[i], "part_" + i);
  }
  
  // Добавление точек
  for (let i in POINTS) {
    if (i !== metaData.currentEpoch.order + "_0") {
      const image = getCurrentEvent(i).content.icon_file || null;
      addPoint(MAP, POINTS[i], "point_" + i, image);
    }
  }
 
  setFuncChapters(POINTS, MAP, route_colors[getOrder()]);

  /* ФУНКЦИОНАЛ */

  cachedChapter(metaData.currentEpoch?.order + "_0");

  // При каждом событии прокрутки проверяйт, какой элемент отображается на экране
  window.onscroll = function () {
    if (isStopListenig) {
      return;
    }
    let chapterNames = Object.keys(POINTS);
    for (let i = 0; i < chapterNames.length; i++) {
      let chapterName = chapterNames[i];
      if (isElementOnScreen(chapterName) === null) {
        break;
      }
      if (isElementOnScreen(chapterName)) {
        setCurrentChapter(chapterName);
        break;
      }
    }
  };

  // Установить активное событие
  function setCurrentChapter(chapterName) {
    if (chapterName === cachedChapter()) return;

    const chapterNames = Object.keys(POINTS);
    const activeIndex = chapterNames.indexOf(chapterName);

    if (chapterName === metaData.currentEpoch.order + "_0") {
      showChapters = [...chapterNames];
      hiddenChapters = [];
      MAP.flyTo({
        center: POINTS[metaData.currentEpoch.order + "_0"].center,
        zoom: POINTS[metaData.currentEpoch.order + "_0"].zoom,
        pitch: POINTS[metaData.currentEpoch.order + "_0"].pitch,
        bearing: POINTS[metaData.currentEpoch.order + "_0"].bearing,
      })
    }

    if (chapterName !== metaData.currentEpoch.order + "_0") {

      showChapters = chapterNames.slice(0, activeIndex);
      hiddenChapters = chapterNames.slice(activeIndex + 1);

      // Начало прорисовки маршрута
      if (chapterName in ROUTE) {
        drawStepByStepEventRoute(
          MAP,
          ROUTE[chapterName].geometry.coordinates,
          "part_" + chapterName
        );
      }
      
    }

    chapterHandler(
      MAP,
      chapterName,
      showChapters,
      hiddenChapters,
      ROUTE
    );

    cachedChapter(chapterName);
  }
}

function setFuncChapters(points, map, lineColor) {
  Object.keys(points).forEach((chapterName) => {
    funcShowChapters[chapterName] = () => {
      map.setPaintProperty("part_" + chapterName, "line-gradient", [
        "step",
        ["line-progress"],
        lineColor || route_colors[chapterName.charAt(0)],
        1,
        "rgba(255, 0, 0, 0)",
      ]);
    };

    funcHiddenChapters[chapterName] = () => {
      map.setPaintProperty("part_" + chapterName, "line-gradient", [
        "step",
        ["line-progress"],
        lineColor || route_colors[chapterName.charAt(0)],
        0,
        "rgba(255, 0, 0, 0)",
      ]);
    };

    funcShowPoints[chapterName] = () => {
      if (chapterName.split('_').slice(-1)[0] !== '0') {
        map.setLayoutProperty(
          "point_" + chapterName,
          "visibility",
          "visible"
        );
      }
    };

    funcHiddenPoints[chapterName] = () => {
      if (chapterName.split('_').slice(-1)[0] !== '0') {
        map.setLayoutProperty(
          "point_" + chapterName,
          "visibility",
          "none"
        );
      }
    };
  });
}


function chapterHandler(map, chapterName, showChapters, hiddenChapters, route) {
  funcShowPoints[chapterName]();
  showChapters.forEach((chapterName) => {
    funcShowPoints[chapterName]();
    if (chapterName in route) {
      funcShowChapters[chapterName]();
    }
  });
  hiddenChapters.forEach((chapterName) => {
    funcHiddenPoints[chapterName]();

    if (chapterName in route) {
      funcHiddenChapters[chapterName]();
    }
  });
}

/**
 * Пошаговая прорисовка маршрута
 * @param {*} map объект карты
 * @param {*} fullRoute массив координат маршрута
 * @param {*} currenValue текущая часть
 */
 function drawStepByStepEventRoute(map, fullRoute, currenValue) {
  const keyRoute = currenValue.replace('part_', '');
  const handleCamera = useRouteCameraPosition(keyRoute, map);

  const progressListener = () => {
    if (keyRoute !== cachedChapter() && !isScrollDown()) {
      window.removeEventListener('scroll', progressListener);
      return;
    }

    const koefVisible = getChapterKoefVisible(keyRoute);
    if (koefVisible === null) {
      window.removeEventListener('scroll', progressListener);
    }
    koefVisible > 0 && handleCamera(koefVisible);

    map.setPaintProperty(currenValue, "line-gradient", [
      "step",
      ["line-progress"],
      route_colors[getOrder()],
      koefVisible,
      "rgba(255, 0, 0, 0)",
    ]);

    koefVisible >= 1 && window.removeEventListener('scroll', progressListener);
  }

  window.addEventListener('scroll', progressListener);
}

function getBounds(id) {
  let element = document.getElementById(id);
  if (!element) return null
  return element.getBoundingClientRect();
}

function getChapterKoefVisible(id) {
  const bounds = getBounds(id);
  if (!bounds) return null;
  const hiddenPart = (bounds.top + bounds.height) - window.innerHeight;
  const float = (bounds.height - hiddenPart) / bounds.height;
  return  hiddenPart > 0 ? float : 1;
}

/**
 * Метод проверки, находится ли элемент сейчас в области видимости
 */
function isElementOnScreen(id) {
  let bounds = getBounds(id);
  if (!bounds) return null;
  return bounds.top > 0 && bounds.top < window.innerHeight;
}

/**
 * Метод добавляющий слой и ресурс части маршрута
 * @param {*} map карта
 * @param {*} part часть маршрута
 * @param {*} name имя части маршрута
 */
 function addRoutePart(map, part, name, showPopup) {
  const popup = new mapboxgl.Popup({
    closeButton: false
  });

  if (!map.getSource(name)) {
    map.addSource(name, { type: "geojson", lineMetrics: true, data: part });
  }
  
  if (showPopup) {
    const mouseEnterListener = (e) => {
      if(toggleRoutesScope.getIsShown()) {
        map.getCanvas().style.cursor = "pointer";
      }
    }
      mapListeners.register('mouseenter', name, mouseEnterListener, map);
      const mouseOverListener = (e) => {
        if (toggleRoutesScope.getIsShown()) {
          cachedPopup() && cachedPopup().isOpen() && cachedPopup().remove();
          cachedPopup(popup);
          popup.setLngLat(e.lngLat)
            .setHTML(getEpochName(name.replace('part_', '').charAt(0)))
            .addTo(map);
        }
      }
      mapListeners.register('mouseover', name, mouseOverListener, map);
      const mouseLeaveListener = (e) => {
        map.getCanvas().style.cursor = "";
        popup.remove();
      }
      mapListeners.register('mouseleave', name, mouseLeaveListener, map);
  }
  // Добавление слоя маршрута
  map.addLayer({
    id: name,
    type: "line",
    source: name,
    layout: {
      "line-join": "round",
      "line-cap": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": route_colors[getOrder()],
      "line-opacity": ["get", "opacity"],
      "line-width": ["get", "width"],
    },
  });
}

/**
 * Метод добавляющий слой и ресурс точки
 * @param {*} map карта
 * @param {*} point точка
 * @param {*} name имя точки
 */

async function addPoint(map, point, name, iconImage = null) {
  cachedPopup() && cachedPopup().remove();
  if (!map.getSource(name)) {
    map.addSource(name, { type: "geojson", data: point });
  }

  if (iconImage) {
    await map.loadImage(iconImage, function (error, image) {
      if (error) throw error;
      if (!map.hasImage(name + "_icon")) {
        map.addImage(name + "_icon", image);
      }
    })  
  }

  const eventMeta = await findPointEvent(name);
  if (eventMeta && eventMeta?.content.video_file) {
    const caption = `${eventMeta.content.name} ( ${eventMeta.date} )`;
    addPointVideo(map, name, caption, eventMeta.content.video_file);
    return;
  }

  const popup = new mapboxgl.Popup({
    closeButton: false
  });

  // Переношу камеру на событие к которому кликнул пользователь
  // Меняю курсор на pointer при наведении на точку
  const clickListener = (e) => {
    cachedPopup() && cachedPopup().remove();
    map.flyTo({
      center: e.features[0].geometry.coordinates,
    });
    map.getCanvas().style.cursor = "pointer";
    const coords = getPointCenter(name) || e.lngLat;
    cachedPopup(popup);
    popup.setLngLat(coords).setHTML(e.features[0].properties.name).addTo(map);
  }
  mapListeners.register('click', name, clickListener, map);

  // Меняю курсор на дефлотное состояние
  // map.on("mouseup", name, () => {
  //   map.getCanvas().style.cursor = "";
  //   popup.remove();
  // });
  // Добавление слоя точки
  const eventType = getEventType(name.replace('point_', ''));
  const defaultIcon = eventType === "big" ? "battle" : "event";
  const icon = iconImage ? name + "_icon" : defaultIcon;
  map.addLayer({
    id: name,
    type: "symbol",
    source: name,
    layout: {
      "icon-image": icon,
      "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      "text-offset": [0, 0.6],
      "icon-size": 1,
      "text-anchor": "top",
      visibility: "visible",
    },
  });
}

function addPointVideo(map, name, caption, video_file) {
  const popup = new mapboxgl.Popup({
    closeButton: false,
  });

  const inflate = () => {
    const button = document.createElement('button');
    button.onclick = function (ev) {
      const event = new Event('requestVideo', {bubbles: true});
      ev.currentTarget.dispatchEvent(event);
      popup.remove();
    }
    button.dataset.video = video_file;
    button.dataset.caption = caption;
    button.className = "rounded-ful self-center";
    const span = document.createElement('span');
    span.textContent = caption;
    const div = document.createElement('div');
    div.appendChild(button);
    div.appendChild(span);
    div.className = "flex flex-col align-center";
    div.onmouseleave = () => popup.remove();
    return div;
  }

  if (mobileRegex.test(window.navigator.userAgent)) {
    const clickListener = (e) => {
      const coords = getPointCenter(name) || e.lngLat;
      popup.setLngLat(coords)
        .setDOMContent(inflate())
        .addTo(map);
    }
    mapListeners.register('click', name, clickListener, map);
  } else {
    const mouseEnterListener = (e) => {
      map.getCanvas().style.cursor = "pointer";
    };
    mapListeners.register('mouseenter', name, mouseEnterListener, map);

    const mouseOverListener = (e) => {
      const coords = getPointCenter(name) || e.lngLat;
      popup.setLngLat(coords)
        .setDOMContent(inflate())
        .addTo(map);
    };
    mapListeners.register('mouseover', name, mouseOverListener, map);
  }
  
  map.addLayer({
    id: name,
    type: "symbol",
    source: name,
    layout: {
      "icon-image": "{icon}",
      "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      "text-offset": [0, 0.6],
      "icon-size": 1,
      "text-anchor": "top",
      visibility: "visible",
    },
  });
}

function removeLayer(map, name) {
  if(map.getLayer(name)) {
    map.removeLayer(name);
  }
}

/**
 * Получить тип текущего события
 * @param {*} idValue идентификатор события
 * @returns тип события
 */
function getEventType(idValue) {
  let evType = new String();
  metaData.currentEpoch.events.forEach((elem) => {
    // Проверяю нашел ли нужное событие
    if (elem.identrifier === idValue) {
      evType = elem.type;
    }
  });
  return evType;
}

function imgMapAdd() {
  var btn = document.getElementById("map-toggle-btn"); 

  if (!bStatus) {
    btn.classList.toggle("opacity-50")

    MAP.flyTo({
      bearing: 15,
      center: [-80.425, 46.437],
      zoom: 5,
      pitch: 40,
    });


    MAP.addSource("portland", {
      type: "image",
      url: "../assets/img/map.png",
      coordinates: [
        [-80.425, 46.437],
        [-71.516, 46.437],
        [-71.516, 37.936],
        [-80.425, 37.936],
      ],
    });
    MAP.addLayer({
      id: "portland",
      type: "raster",
      source: "portland",
      paint: {
        "raster-fade-duration": 0,
      },
    });
  } else {  
    btn.classList.toggle("opacity-50")

    MAP.removeLayer("portland");
    MAP.removeSource("portland");
  }

  bStatus = !bStatus
}

function getCurrentEvent(identrifier) {
  if (!metaData.currentEpoch) {
    return null;
  }
  return Object.values(metaData.currentEpoch.events).find((event) => {
    return event.identrifier === identrifier
  }) || { content: {}};
}

function findPointEvent(name) {
  const eventName = name.replace('point_', '');
  const currrentEvent = metaData.currentEpoch.events.find((event) => event.identrifier === eventName);
  return currrentEvent;
}

function getEpochName(order) {
  const currentEpoch = metaData.otherEpochs.find((epoch) => epoch.order === Number(order));
  return currentEpoch ? currentEpoch.name : '';
}

function getPointCenter(point) {
  return POINTS[point.replace('point_', '')]?.features[0]?.geometry?.coordinates || null;
}

function setMetaData() {
  let order = getOrder();

  let currentEpoch = data?.epochs?.find((epoch) => epoch.order === Number(order));
  let otherEpochs = data?.epochs?.filter((epoch) => epoch.order !== Number(order));
  let currentRoute = data?.routes?.find((route) => Object.keys(route).find((key) => key.split('_')[0] === order));
  let otherRoutes = data?.routes?.filter((route) => Object.keys(route).find((key) => key.split('_')[0] !== order));

  // POINTS = JSON.parse(currentEpoch.content.event_points);
  POINTS = currentEpoch?.content?.event_points ? JSON.parse(currentEpoch.content.event_points) : {};
  ROUTE = currentRoute
  ANOTHER_POINTS = otherEpochs?.map((epoch) => JSON.parse(epoch.content.event_points));
  ANOTHER_ROUTES = otherRoutes;

  metaData = {...metaData, currentEpoch, otherEpochs, currentRoute, otherRoutes};
  return metaData;
}

function getOrder() {
  return new URLSearchParams(window.location.search).get('order');
}


function useIsScrollingDown() {
  let prevY = 0;
  return () => {
    let isScrollingDown = window.scrollY > prevY;
    prevY = window.scrollY;
    return isScrollingDown;
  }
}

function useCachedValue(init) {
  let currenValue = init;
  return (newValue) => {
    if (newValue) {
      currenValue = newValue;
    }
    return currenValue;
  }
}


function useRouteCameraPosition(keyRoute, map) {
  const part = metaData.currentRoute[keyRoute];
  const coordinatesArray = part.geometry.coordinates;
  let point = coordinatesArray[0];
  let pointProperties = POINTS[keyRoute].features[0].properties;

  function getIndex(float) {
    const length = coordinatesArray.length;
    const result = Math.round(length * float);
    const index = result > 0 && result < coordinatesArray.length - 1 ? result : coordinatesArray.length - 1;
    return index;
  }

  //float between 0 and 1
  return (float) => {
    if (Array.isArray(coordinatesArray )) {
      if (!turf) {
        point = coordinatesArray[getIndex(float)];
      }
      if (!!turf && coordinatesArray.length > 4) {
        let lineCoords = turf.lineString(coordinatesArray);
        let lineLength = turf.length(lineCoords);
        let along = turf.along(lineCoords, lineLength *  float);
        let prev = point;
        point = along?.geometry?.coordinates || prev;
      }
      map.flyTo({
        bearing: pointProperties.bearing,
        center: Array.isArray(point) ? point : POINTS[keyRoute].features[0].geometry.coordinates,
        zoom: pointProperties.zoom,
        pitch: pointProperties.pitch,
      })
    }
  }
}

function showAnotherRoutes() {
  ANOTHER_POINTS.forEach((pointGroup, index) => {
    for (let key in pointGroup) 
    {
      if (key.split('_').slice(-1)[0] !== '0') {
        funcShowPoints[key]();
        key in ANOTHER_ROUTES[index] && funcShowChapters[key]();
      }
    }
  })
}

function hideAnotherRoutes() {
  ANOTHER_POINTS.forEach((pointGroup, index) => {
    for (let key in pointGroup) 
    {
      if (key.split('_').slice(-1)[0] !== '0') {
        funcHiddenPoints[key]();
        key in ANOTHER_ROUTES[index] && funcHiddenChapters[key]();
      }
    }
  })
}

//  создаёт окружение для хранения позиции камеры между переключением маршрутов
function useCameraState(map) {
  let cameraState = setcameraState(map);
  let allRoutescameraState = initShowRoutesCameraProperties;

  function setcameraState(map) {
    return {
      center: map.getCenter(),
      zoom: map.getZoom(),
      pitch: map.getPitch(),
      bearing: map.getBearing(),
    }
  }

  return function(isShown){
    if(!isShown) { 
      cameraState = setcameraState(map);
    };
    const pos = isShown ? cameraState : allRoutescameraState;
    map.flyTo(pos);
  }
}

//  создаёт окружение для обработчика переключение маршрутов
function useToggleRoutesScope() {
  let isShown = false;
  let handler = null;
  return {
    click: function(ev) {
      isShown && cachedPopup() && cachedPopup().isOpen() && cachedPopup().remove();
      isShown ? hideAnotherRoutes() : showAnotherRoutes();
      ev.currentTarget.classList.toggle("opacity-50");
      handler && handler(isShown);
      isShown = !isShown;
    },
    getIsShown: function() {
      return isShown;
    },
    addHandler: function (callback) {
      if (typeof callback === 'function') {
        handler = callback;
      }
    } 
  }
}

//  создаёт окружение для кастомных  обработчиков пользовательских действий карты
function useRigsterListenersScope() {
  let listeners = [];

  return {
    register(event, layer, handler, map) {
      const listener = {event, layer, handler};
      listeners.push(listener);
      map.on(event, layer, handler);
      return listener;
    },
    getListener(layer) {
      return listeners.find((item) => item.layer === layer) || null;
    },
    unregisterListener(layer) {
      return listeners.filter((item) => item.layer !== layer);
    },
    removeListeners(map) {
      listeners.forEach((listener) => {
        const {event, layer, handler} = listener;
        map.off(event, layer, handler);
      })
      listeners = [];
    }
  }
}
